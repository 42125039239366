// https://iosref.com/res/
export const iosDeviceModels = {
  '640x960': 'iPhone 4/4s/iPod touch (gen 4)',
  '640x1136': 'iPhone 5/5s/5c/SE/iPod touch (gen 5,6)',
  '750x1334': 'iPhone 6/6s/7/8',
  '768x1024': 'iPad mini (gen 1)/iPad (gen 1,2)',
  '828x1792': 'iPhone XR/11',
  '1125x2436': 'iPhone X/XS/11 Pro',
  '1242x2208': 'iPhone 6+/6s+/7+/8+',
  '1242x2688': 'iPhone XS Max/11 Pro Max',
  '1536x2048': 'iPad/iPad mini 4',
  '1620x2160': 'iPad (gen 7)',
  '1668x2224': 'iPad Air 10.5"/iPad Pro 10.5"',
  '1668x2388': 'iPad Pro 11"',
  '2048x2732': 'iPad Pro 12.9"',
};

// NOTE: this values are equal to same at 'express-device'
export const deviceTypes = {
  phone: 'phone',
  tablet: 'tablet',
  desktop: 'desktop',
};

export const defaultDeviceModel = 'Unknown';
