let devicePropsStore = false;

export const storeDeviceProps = (deviceProps) => {
  devicePropsStore = deviceProps;
};

export const getDeviceProps = () => {
  if (!devicePropsStore && !__CLIENT__) {
    /* eslint-disable no-console */
    console.error(
      'It seems you have attempted to access \'thisDevice\' not in runtime env\nIt is prohibited!',
    );
    console.trace();
    /* eslint-enable no-console */
  }

  return devicePropsStore;
};
