import { getLoaderInstance } from './instanceStorage';

function getLoaderApi() {
  // TODO: Это API к лоадеру, его нужно причесать и покрыть тестами
  const instance = getLoaderInstance();
  return {
    start: instance.start,
    setStep: instance.setStep,
    setStepProgress: instance.setStepProgress,
    finish: instance.finish,
    hide: instance.hide,
    show: instance.show,

    // TODO: перенести stepsIndexes из инстанса в переменную
    getStepsIndexes: () => {
      return instance.stepsIndexes;
    },

    // TODO: переделать на промис
    isFinished: () => {
      return instance.state.finished;
    },
  };
}

export default getLoaderApi;
