import SessionsManager from './SessionsManager';

export const sessionsManager = new SessionsManager();

export function initializeJSFillerStores() {
  // add all stores here via interface addStore
  window.JSFillerStores = {
    ...window.JSFillerStores,
    sessionStore: sessionsManager.getStore(),
  };
}

export function getWsTransport() {
  return {
    getWebSocketClient: () => {
      const currentSession = sessionsManager.getCurrentSession();
      return currentSession && currentSession.websocketClient;
    },
    onWebSocketClientChanged: (cb) => {
      sessionsManager.onSessionChanged(cb);
    },
  };
}
