import thisDevice from './thisDevice';
import { deviceTypes } from './utils/const';
import { storeDeviceProps, getDeviceProps } from './devicePropsStore';

if (__CLIENT__ && !__TEST__) {
  // eslint-disable-next-line no-underscore-dangle
  const nodeDevice = window.__useragent;

  // useragent as macOS and with touchPoints could only be iPad with iOS13
  const isIPadIOS13 = nodeDevice.os === 'macOS' && navigator.maxTouchPoints > 1;
  if (isIPadIOS13) {
    storeDeviceProps({
      ...nodeDevice,
      type: deviceTypes.tablet,
      os: 'iOS',
      discardSSR: true,
    });
  } else {
    storeDeviceProps(nodeDevice);
  }
}

export {
  thisDevice,
  getDeviceProps,
  deviceTypes,
};
