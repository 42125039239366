import { getDeviceProps } from './devicePropsStore';
import * as deviceSelectors from './utils/functions';

const thisDevice = {};

Object.keys(deviceSelectors).forEach((selectorName) => {
  Object.defineProperty(thisDevice, selectorName, {
    get() {
      return deviceSelectors[selectorName](getDeviceProps());
    },
    // for tests
    configurable: true,
  });
});

export default thisDevice;
