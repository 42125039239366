import get from 'lodash/get';
import { defaultDeviceModel, iosDeviceModels, deviceTypes } from './const';

/* devices types */
export const isDesktop = (deviceProps) => {
  if (!deviceProps) {
    return false;
  }
  return deviceProps.type === deviceTypes.desktop;
};

export const isPhone = (deviceProps) => {
  if (!deviceProps) {
    return false;
  }
  return deviceProps.type === deviceTypes.phone;
};

export const isTablet = (deviceProps) => {
  if (!deviceProps) {
    return false;
  }

  return deviceProps.type === deviceTypes.tablet;
};

export const isMobile = (deviceProps) => {
  return isPhone(deviceProps) || isTablet(deviceProps);
};

/* OS's */
export const isAndroid = (deviceProps) => {
  if (!deviceProps) {
    return false;
  }

  return deviceProps.os === 'Android';
};

export const isIOS = (deviceProps) => {
  if (!deviceProps) {
    return false;
  }

  return deviceProps.os === 'iOS';
};

export const isMacOSX = (deviceProps) => {
  if (!deviceProps) {
    return false;
  }
  const { os } = deviceProps;
  return os === 'Mac_OS_X' || os === 'macOS';
};

const isWindows = (deviceProps) => {
  if (!deviceProps) {
    return false;
  }

  const { os } = deviceProps;
  return (os === 'Windows_NT' || os === 'Windows');
};

/* browsers */
const isEdge = (deviceProps) => {
  if (!deviceProps) {
    return false;
  }

  return deviceProps.browserName === 'Edge';
};

const isFirefox = (deviceProps) => {
  if (!deviceProps) {
    return false;
  }

  return deviceProps.browserName === 'Firefox';
};

const isSafari = (deviceProps) => {
  if (!deviceProps) {
    return false;
  }

  return deviceProps.browserName === 'Safari';
};

const isChrome = (deviceProps) => {
  if (!deviceProps) {
    return false;
  }

  return deviceProps.browserName === 'Chrome';
};

const isOpera = (deviceProps) => {
  if (!deviceProps) {
    return false;
  }

  return deviceProps.browserName === 'Opera';
};

export const isInternetExplorer11 = (deviceProps) => {
  if (!deviceProps || !isDesktop(deviceProps)) {
    return false;
  }

  const { browser } = deviceProps;
  const splittedBrowserProps = browser.split('@');
  if (splittedBrowserProps.length && splittedBrowserProps.length === 2) {
    const version = splittedBrowserProps[1].split('.');
    if (version.length && version[0] === '11' && splittedBrowserProps[0] === 'Internet_Explorer') {
      return true;
    }
  }

  return false;
};

/* combined selectors */
export const isEdgeDesktop = (deviceProps) => {
  return isDesktop(deviceProps) && isEdge(deviceProps);
};

export const isFirefoxDesktop = (deviceProps) => {
  return isDesktop(deviceProps) && isFirefox(deviceProps);
};

export const isSafariDesktop = (deviceProps) => {
  return isDesktop(deviceProps) && isSafari(deviceProps);
};

export const isChromeDesktop = (deviceProps) => {
  return isDesktop(deviceProps) && isChrome(deviceProps);
};

export const isSafariPhone = (deviceProps) => {
  return isMobile(deviceProps) && isSafari(deviceProps);
};

export const isChromeAndroid = (deviceProps) => {
  return isAndroid(deviceProps) && isChrome(deviceProps);
};

export const isChromeIOS = (deviceProps) => {
  if (!deviceProps) {
    return false;
  }

  return isIOS(deviceProps) && deviceProps.browserName === 'CriOS';
};

export const isWindowsDesktop = (deviceProps) => {
  return isWindows(deviceProps) && isDesktop(deviceProps);
};

export const isOperaDesktop = (deviceProps) => {
  return isOpera(deviceProps) && isDesktop(deviceProps);
};

// track all desktop devices with touch displays, rename?
export const isSurface = (deviceProps) => {
  if (!isDesktop(deviceProps)) {
    return false;
  }

  const maxTouchPoints = isEdgeDesktop(deviceProps)
    ? get(window, 'navigator.msMaxTouchPoints', 0)
    : get(window, 'navigator.maxTouchPoints', 0);

  return maxTouchPoints > 1;
};

const getScreenResolution = () => {
  const ratio = window.devicePixelRatio;

  return {
    height: window.screen.height * ratio,
    width: window.screen.width * ratio,
  };
};

export const iosDeviceModel = (deviceProps) => {
  if (!deviceProps || !__CLIENT__) {
    return defaultDeviceModel;
  }
  const { height, width } = getScreenResolution();
  return iosDeviceModels[`${width}x${height}`] || defaultDeviceModel;
};

export const discardSSR = (deviceProps) => {
  return Boolean(deviceProps.discardSSR);
};
